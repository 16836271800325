
.info-panel {
    width: 480px;
    height: 730px;
    border-radius: 16px;
    background: var(--Background-surface-primary, #1D1D1E);
    z-index: 999;
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--Text-text-primary, #FFF);
    font-family: "Space Grotesk";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-items: center;
  }
  
  .info-panel.visible {
    display: block;
  }

  .titulo-info{
    color: #FFF;
    font-family: "Space Grotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    left: 0%;
    margin-left: 5%;
    margin-top: 10%;
    width: 80%;
  }
  
  .close-button {
    position: absolute;
    width: 36px;
    height: 36px;
    background: var(--Background-surface-primary, #1D1D1E);
    right: 5%;
    top: 6.5%;
    appearance: none;
    border: none; 
    cursor: pointer;
  }

  .info-panel h4{
    width: 92%;
    color: #FFF;
    font-size: 16px;
    padding-left: 5%;
    font-weight: 200;
    margin: 1%;
  }

  .info-panel h2{
    color: #FFF;
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }

  .separation-line{
    width: 90%;
    height: 1px;
    margin-left: 4%;
    margin-top: 5%;
    background: var(--Button-button-stroke-inactive, #FFF);
  }


  video{
    width: 90%;
    margin-left: 5%;
    border-radius: 5%;
  }

  @media screen and (max-height: 800px) {
    .info-panel {
      top: 41%;
      transform: translate(-50%, -53%) scale(0.85);
    }
  }