body{
  background-color: var(--Fondo, #1D1D1E);
  color: #F4F4F4;
  overflow: hidden;
  min-width: 40rem;
  min-height: 20rem;
  cursor:default;
  color: #FFF;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.vertical {
  position: absolute;
  top: 0;
  left: 1.38rem;
  font-weight: 500;
}

.din {
  display: flex;
  align-items: flex-start;
  gap: 9px;
}

.a {
  display: flex;
  align-items: flex-start;
  height: 3.7vh;
  gap: 16px;
  padding: 10px 0px 19px;
}

.a span{ 
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.a input{
  width: 60px;
  background: none;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  border: 0px solid #ccc; 
  box-shadow: none !important; 
  color: white;
  border-bottom: 1px solid white;
  color: var(--Text-text-secundary, #898989);
}

.a input::-webkit-inner-spin-button,
.a input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.a input:focus {
  outline: none; 
}

.a input::placeholder {
  color: white; 
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}

.ancho{
  margin-left: 17px;
}

.oval-button {
  display: flex;
  height: 46px;
  width: 102.5px;
  padding: 10px 24px;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--Texto---iconos, #F4F4F4);
  background: var(--Fondo, #1D1D1E);
  cursor: pointer;
}

.a-oval-button {
  display: flex;
  width: 54.5px;
  height: 26.194px;
  padding: 0px 4.03px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10.075px;
  border-radius: 8.06px;
  border: 1.007px solid var(--Texto---iconos, #F4F4F4);
  background: var(--Fondo, #1D1D1E);
  cursor: pointer;
}

.oval-button-text {
  color: var(--Button-button-text-secundary, #FFF);
  font-family: Space Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.oval-button:hover .oval-button-text,
.oval-button.selected .oval-button-text{
  color: var(--Texto---iconos, #141414);
}
.oval-button.selected{
  background: var(--Fondo, #F4F4F4);
}
.a-oval-button:hover .a-oval-button-text,
.a-oval-button.selected .a-oval-button-text {
  color: var(--Texto---iconos, #141414);
}
.a-oval-button.selected{
  background: var(--Fondo, #F4F4F4);
}

.a-oval-button-text {
  color: var(--Texto---iconos, #F4F4F4);
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.oval-button:hover{
  background-color: #9B9B9B;
  color: #1D1D1E;
}
.oval-button:focus {
  background-color: #F4F4F4;
  color: #1D1D1E;
}

.a-oval-button:hover{
  background-color: #9B9B9B;
  color: #1D1D1E;
}
.a-oval-button:focus {
  background-color: #F4F4F4;
  color: #1D1D1E;
}

.ellipse-parent {
  position: relative;
  width: 5.13rem;
  height: 1.25rem;
}
.group-item {
  position: absolute;
  top: 0.19rem;
  left: 0;
  border-radius: 50%;
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  width: 0.88rem;
  height: 0.88rem;
}


.radio-input {
  margin-right: 5px;
}

.orientation-selector {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.svg-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selection-vh-label{
  color: var(--Text-text-primary, #FFF);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 0.2rem ;
}
.svg-container svg circle {
  stroke-width: 1px;
  fill: var(--Button-button-backgound-active, #040404);
}

.svg-container.selected svg circle {
  fill: var(--Button-button-backgound-active, #FFF);
  stroke-width: 1px;
  stroke: var(--Button-button-stroke-inactive, #FFF);
}

.ellipse-group {
  position: relative;
  width: 6.31rem;
  height: 1.25rem;
}
.orientacin {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.alto-pagina {
  color: var(--Text-text-secundary, #898989);
  text-align: right;
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  right: 4%;
}
.div-2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
} 

.body {
  position: absolute;
  width: 96%;
  top: 10.13rem;
  left:3%;
  right: 3;
}
.frame-item {
  position: absolute;
  top: 8.09rem;
  left: 2.78rem;
  border-top: 2px solid var(--color-white);
  box-sizing: border-box;
  width: 467px;
  height: 1px;
  background: var(--Texto---iconos, #F4F4F4);
  width: 467px;
  height: 1px;
}


.help-icon {
  width: 34px;
  height: 34px;
  margin-left: 6%;
}

.user-icon{
  margin-right: 6%;
}


.info-icon:hover,
.user-icon:hover,
.settings-icon:hover,
.help-icon:hover{
  transform: scale(1.1);
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12%;
  width: auto;
  margin: 0;
  margin-top: 1%;
}

.easy-grid {
  width: 270px;
  height: auto;
  margin-left: 6%;
  margin-right: 6%;
  padding: 0;
  aspect-ratio: "195/29";
}

.div2-izquierda {
  position: absolute;
  left: 2%;
  top: 29%;
  width: 230px;
  height: 309px;
}

.interlineado{
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  left:0.35rem;
}

.interlineado-input{
  color: var(--Text-text-secundary, #898989);
  background: none;
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  top: 2rem;
  left:0.28rem;
  width: 204px;
  flex: 1; 
  text-align: left;
  flex-grow: 1;
}

.interlineado-unit{
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;   
  top: 2rem;
  left: 4rem;
  width: 24px;
  right: 8px;
}

.interlineado-input::-webkit-inner-spin-button,
.interlineado-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wireframe .interlineado-input {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  border: 0px solid #ccc; 
  box-shadow: none !important; 
}

.wireframe .interlineado-input:focus {
  outline: none; 
}

.interlineado-separation-line{
  position: absolute;   
  top: 3.9rem;
  left:0.28rem;
  width: 204px;
  height: 1px;
  right: 8px;
  border-top: 2px solid var(--color-white);
  box-sizing: border-box;
  height: 1px;
  background: var(--Texto---iconos, #F4F4F4);
}

.margen-superior{
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 5rem;
  left:0.35rem;
}

.margen-superior-valor-exacto,
.margen-superior-input{
  color: var(--Text-text-secundary, #898989);
  background: none;
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  top: 7rem;
  left:0.28rem;
  width: 65px;
  flex: 1; 
  text-align: left;
  flex-grow: 1;

}
.margen-inferior-valor-exacto,
.margen-superior-valor-exacto{
  position: relative;
  color: var(--Text-text-color, #09E48D);
  left:10rem;
  top: 30%;
  width: 50px;
  cursor: pointer;
}

.margen-superior-unit{
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;   
  top: 7rem;
  left:4.3rem;
  width: 24px;
  right: 8px;
}

.margen-superior-input::-webkit-inner-spin-button,
.margen-superior-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wireframe .margen-superior-input {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  border: 0px solid #ccc; 
  box-shadow: none !important; 
}

.wireframe .margen-superior-input:focus {
  outline: none; 
}

.margen-superior-separation-line{
  position: absolute;   
  top: 9rem;
  left:0.35rem;
  width: 90px;
  height: 1px;
  right: 8px;
  border-top: 2px solid var(--color-white);
  box-sizing: border-box;
  height: 1px;
  background: var(--Texto---iconos, #F4F4F4);
}

.margen-inferior{
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 10rem;
  left:0.35rem;
}

.margen-inferior-valor-exacto,
.margen-inferior-input{
  color: var(--Text-text-secundary, #898989);
  background: none;
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  top: 12rem;
  left: 0.28rem;
  width: 65px;
  flex: 1; 
  text-align: left;
  flex-grow: 1;
}

.margen-inferior-valor-exacto{
  color: var(--Text-text-color, #09E48D);
  left:10rem;
  width: 50px;
  cursor: pointer;
}

.margen-inferior-unit{
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;   
  top: 12rem;
  left: 4.3rem;
  width: 24px;
  right: 8px;
}

.margen-inferior-input::-webkit-inner-spin-button,
.margen-inferior-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wireframe .margen-inferior-input {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  border: 0px solid #ccc; 
  box-shadow: none !important; 
}

.wireframe .margen-inferior-input:focus {
  outline: none; 
}

.margen-inferior-separation-line{
  position: absolute;   
  top: 14rem;
  left:0.35rem;
  width: 90px;
  height: 1px;
  right: 8px;
  border-top: 2px solid var(--color-white);
  box-sizing: border-box;
  height: 1px;
  background: var(--Texto---iconos, #F4F4F4);
}

.arrow-1,
.arrow-2{
  position: relative;   
  top: 7rem;
  left:7rem;  
}

.arrow-2{
  position: absolute;
  top: 12.5rem;
  left:7rem;  
}

.lineas-disponibles{
  color: var(--Text-text-primary, #FFF);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 78%;
  left: 3%;
  width: 100%;
}
.cant-lineas-disp,
.disponibles{
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  top: 95%;
  width: 100px;
}

.cant-lineas-disp{
  max-width: 200px;
  font-size: 12px;
}
.boton-mas-menos-lineas {
  display: flex;
  padding: 5px 33px;
  align-items: flex-start;
  gap: 88px;
  border-radius: 20px;
  background: var(--Button-button-backgound-active, #FFF);
  height: 28px;
  width: 150px;
  top: 100%;
  position: absolute;
  cursor: pointer;
  margin-top: 2%;
}

.boton-mas-menos-lineas button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.boton-menos,
.boton-mas {
  width: 24px;
  height: 24px;
  border: 0;
  background: var(--Button-button-backgound-active, #FFF);
  cursor: pointer;
}


.div3{
  position: absolute;
  top: 60%;
  width: 100%;
  height: 120px;
  left: 2%;
}

.rectangle-parent {
  position: absolute;
  width: 37rem;
  height: 64rem;
  font-size: var(--font-size-xl);
  background-color: var(--Fondo, #1D1D1E);
  padding: 0;
  padding-top: 20px;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 2%;
}

.rectangle-parent.panel-visible{
  background-color: rgba(0, 0, 0,0);
}


.wireframe.panel-visible {
  background-color: rgba(0, 0, 0, 0.2);
}

.rounded-table {
  border-collapse:collapse;
  position: absolute;
  top: 29%; 
  left: 42.5%;
  border-radius: 16px 16px 0px 0px;
  width: 316px;
  height: 303px;
}

.rounded-table tbody {
  position: absolute;
  height: 232px;
  overflow: hidden;
  border-radius: 0px 0px 16px 16px;
  border: 1px solid var(--Button-button-stroke-inactive, #FFF);
  top: 71px;
  width: 313px;
  text-align: center;
}

.rounded-table thead{
  display: flex;
  position: absolute;
  width: 314.5px;
  height: 71px;
  flex-shrink: 0;
  background: var(--Button-button-backgound-active, #FFF);
  border-radius: 16px 16px 0px 0px;
}

.rounded-table td{
  text-align: center;
}

.fila-par {
  transform: translateY(175%); 
  background-color: #353535; 
  margin-left: 5%;
}

.fila-impar {
  transform: translateY(175%); 
  background-color: #1D1D1E; 
  margin-left: 5%;
}

.rounded-table tbody tr {
  display: flex;
  width: 92%; 
}

.separation-line-table{
  position: absolute;   
  top: 6.5rem;
  width: 92%;
  height: 1px;
  left: 5%;
  right: 5%;
  border-top: 2px solid var(--color-white);
  box-sizing: border-box;
  background: var(--Texto---iconos, #F4F4F4);
}

.rounded-table tbody tr .primer-valor {
  flex-basis: 46%;
  padding-right: 15%;
}

.rounded-table tbody tr .segundo-valor {
  flex-basis: 46%;
  padding-right: 10%;
}

.modulos-ok,
.pt-margen-a {
  padding-top: 0.2rem;
  padding-left: 1rem;
  display: inline-block;
  color: var(--Button-button-text-primary, #1D1D1E);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.pt-margen-a {
  position: relative;
}

.valor-modulos-ok ,
.valor-pt-margen-a {
  color: var(--Button-button-text-primary, #1e1d1d);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  right: 5%;
  cursor: pointer;
}

.valor-pt-margen-a{
  text-decoration-line: underline;
}

.valor-modulos-ok {
  cursor: pointer;
}

.pt-margen-container,
.modulos-ok-container {
  display: flex;
  align-items: center;
  gap: 5rem; 
  margin-bottom: 10px; 
}

.pt-margen-a,
.valor-pt-margen-a,
.modulos-ok,
.valor-modulos-ok {
  margin: 0; 
}

.table-column-name-iz{
  top: 110%;
  left: 0.8rem;
  position: absolute;
  color: var(--Text-text-primary, #FFF);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;  
}


.table-column-name-de{
  top: 110%;
  right: 4rem;
  position: absolute;
  color: var(--Text-text-primary, #FFF);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;  
}

.separation-table-line{
  position: absolute;
  top: 2rem;
  left: 0.84rem;
  align-items: center;
  border-top: 2px solid var(--color-white);
  box-sizing: border-box;
  width: 467px;
  height: 1px;
  background: var(--Texto---iconos, #F4F4F4);
  width: 287px;
  height: 1px;
}

.porcentaje-inferior-superior,
.opciones-avanzadas{
  position: absolute;
  left: 0;
  padding-left: 1%;
  width: 50%;
  height: 160px;
  color: var(--Text-text-primary, #FFF);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.porcentaje-inferior-superior{
  position: absolute;
  top: 27%;
  right: 0%;
  left: 50%;
}
.porcentaje-text{
  position: absolute;
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sobrante-num,
.sobrante{
  position: absolute;
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  top: 12%;
}

.sobrante-num{
  left: 59%;
}
.porcentaje-text{
  position: absolute;
  right: 3;
}

.superior-inferior{
  position: absolute;
  top: 40%;
  justify-content: space-between;
}

.svg-container {
  margin-bottom: 10px; 
}
.sobrante-text{
  position: absolute;
  color: var(--Text-text-secundary, #898989);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  top: 12%;
  left: 70%;
}

.porcentaje-inferior{
  position: absolute;
  right: 3;
  top: 38%;
}
.porcentaje-superior{
  position: absolute;
  right: 3;
  top: 20%;
}
.signo-porcentaje,
.porcentaje-inferior-input,
.porcentaje-superior-input{
  color: var(--Text-text-primary, #FFF);
  font-family: Space Grotesk;
  font-size: 16px;
  background: var(--Background-surface-primary, #1D1D1E);
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  left: 130%;
  top: -9.5%;
  width: 45%;
  outline: none; 
}

.porcentaje-inferior-input{
  left: 150%;
  width: 50%;
}
.porcentaje-inferior-input::-webkit-inner-spin-button,
.porcentaje-inferior-input::-webkit-inner-spin-button,
.porcentaje-superior-input::-webkit-inner-spin-button,
.porcentaje-superior-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.wireframe .porcentaje-inferior-input ,
.wireframe .porcentaje-superior-input {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  border: none; 
  box-shadow: none !important; 
}
.signo-porcentaje-2,
.signo-porcentaje-1{
  position: absolute;
  top: 2rem;
  left: 8.4rem;
  width: 24px;
  right: 8px;
  margin: 0;
}

.signo-porcentaje-2{
  top: 3.85rem;
}

.porcentaje-separation-line-1,
.porcentaje-separation-line-2{
  position: absolute;   
  top: 5rem;
  left:5rem;
  width: 90px;
  height: 1px;
  right: 8px;
  border-top: 2px solid var(--color-white);
  box-sizing: border-box;
  height: 1px;
  background: var(--Texto---iconos, #F4F4F4);
}

.porcentaje-separation-line-1{
  top: 3.2rem;
}

.div4{
  position: relative;
  top: 59.5%;
  width: 99%;
  height: 10%;
  left:3%;
  color: var(--Text-text-primary, #FFF);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.parrafo-informacion-final{
  color: var(--Colors-Gray---Type, #898989);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  text-align: justify;
  width: 100%;
}

.h2-1,
.h2-2,
.h2-3,
.h2-4,
.h2-5,
.h2-6{
  position: absolute;
  color: var(--Colors-Gray---Type, #898989);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  top: 10%;
}

.h2-1{
  left:0;
}

.h2-2{
  left:27%;
}

.h2-3{
  left: 57%;
}

.h2-4{
  top: 35%;
  left:0;
}

.h2-5{
  top: 35%;
  left:27%;
}

.h2-6{
  top: 35%;
  left:57%;
}


.wireframe {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0vh;
  min-width: 600px;
  min-height: 900px;
}

.my-toast{
  width: 270px; 
  height: 50px; 
  background-color: #f0f0f0; 
  border-radius: 5px; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
}

@media screen and (height <= 800px) {
  .rectangle-parent {
    top: 41%;
    transform: translate(-50%, -50%) scale(0.85);
  }
}

@media screen and (width <= 1200px) {

  .rectangle-parent {
    top: 43%;
    transform: translate(-50%, -50%) scale(0.85);
  }
}





