
  .settings-panel {
    width: 480px;
    height: 730px;
    border-radius: 16px;
    background: var(--Background-surface-primary, #1D1D1E);
    z-index: 999;
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--Text-text-primary, #FFF);
    font-family: "Space Grotesk";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-items: center;
  }
  
  .settings-panel.visible {
    display: block;
  }

  .titulo{
    position: absolute;
    left: 43%;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .close-button {
    position: absolute;
    width: 36px;
    height: 36px;
    background: var(--Background-surface-primary, #1D1D1E);
    right: 5%;
    top: 6%;
    appearance: none;
    border: none; 
    box-shadow: none !important; 
  }

  .unidad-medida{
    position: absolute;
    top: 23%;
    width: 100%;
    height: 5%;
  }

  .seleccionar-medida{
    position: absolute;
    left: 8%;
  }

  .selector-idioma,
  .selector{
    position: absolute;
    right: 6%;
    color: var(--Text-text-secundary, #898989);
    text-align: right;
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    border: none;
    background-color: transparent; 
    cursor: pointer;
  }

  .selector-idioma option,
  .selector option {
    font-size: 12px;
  }

  .SCOPE{
    position: absolute;
    top: 31%;
    left: 8%;
  }

  .SCOPE p{
    color: var(--Text-text-secundary, #898989);
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-top: 2%;
    width: 402px;
  }

  .SCOPE input{
    width: 60px;
    background: none;
    appearance: none;
    border: 0px solid #ccc; 
    box-shadow: none !important; 
    color: white;
    border-bottom: 1px solid white;
    color: var(--Text-text-secundary, #898989);
    position: absolute;
    right: 0;
  }

  .a input::placeholder {
    color: white; 
    text-align: center;
    font-family: "Space Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.5;
  }

  .SCOPE input::-webkit-inner-spin-button,
  .SCOPE input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  
  .SCOPE input:focus {
    outline: none; 
  }

  .Ayudas-Toggle,
  .Modo-Oscuro-Toggle{
    position: absolute;
    top: 15%;
    left: 300%;
    width: 80%;
    height: 40%;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .idioma{
    position: absolute;
    top: 15%;
    left: 8%;
    width: 85%;
  }

  .selector-idioma{
    left: 75%;
    width: 25%;
  }

  .ayudas{
    position: absolute;
    top: 45%;
    left: 8%;
  }

  .iniciar-sesion{
    position: absolute;
    top: 60%;
    left: 8%;
  }

  .iniciar-sesion p{
    color: var(--Text-text-secundary, #898989); 
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 88%;
  }

  .ayudas p{
    width: 402px;
    color: var(--Text-text-secundary, #898989);
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .Ayudas-Toggle{
    top: 0%;
    left: 57%;
  }
  .separation-line-1,
  .separation-line-2,
  .separation-line-3,
  .separation-line-5,
  .separation-line-4{
    width: 412px;
    height: 1px;
    background: var(--Button-button-stroke-inactive, #FFF);
    position: absolute;
    margin-left: 6.5%;
    margin-right: 5%;
  }

  .separation-line-1{
    top: 20%;
  }

  .separation-line-2{
    top: 28%;
  }

  .separation-line-3{
    top: 43%
  }

  .separation-line-4{
    top: 58%;
  }

  .separation-line-5{
    top: 84%;
  }

  .iniciar-sesion button{
    display: flex;
    width: 90%;
    justify-content: center;
    border-radius: 10px;
    background: var(--Button-button-backgound-active, #FFF);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17), 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
    border: none;
  }
  .google-rectangle{
    display: flex;
    width: 85%;
    padding: 15px;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    border: none;
    cursor: pointer;
  }

  .google-rectangle label{
    color: var(--Text-text-secundary, #898989);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 9%;
    cursor: pointer;
  }

  .google-logo{
    position: absolute;
    left: 16%;
  }

  .version{
    position: absolute;
    bottom: 2.5%;
    width: 25%;
    left: 38%;
    color: var(--Text-text-secundary, #898989);
    text-align: center;
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .contacto{
    width: 85%;
    position: absolute;
    bottom: 10%;
    left: 8%;
  }

  .contacto label{
    color: var(--Text-text-secundary, #898989);
    text-align: right;
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    position: absolute;
    right: 1%;
    cursor: pointer;
  }

  @media screen and (max-height: 800px) {
    .settings-panel {
      top: 41%;
      transform: translate(-50%, -53%) scale(0.85);
    }
  }