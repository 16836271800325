

  .pt-margen-a .hover-element {
    display: none;
  }
  
  .pt-margen-a:hover .hover-element {
    display: block;
  }

  .hover-element{
    position: absolute;
    width: 177px;
    height: auto;
    text-align: left;
    z-index: 999; 
    padding: 0;
    margin: 0;
    border-radius: 20.978px 20.978px 20.978px 20.978px;
    background: var(--Button-button-backgound-active, #ffffff);
  }

  .hover-element h2{
    font-size: 16px;
  }

  .hover-element img{
    width: 100%;
    height: auto;
  }

.hover-element .separation-line{
    width: 100%;
    background-color: #1D1D1E;
    margin: 0;
}

  .hover-element p{
    color: var(--Button-button-text-primary, #1D1D1E);
    font-family: "Space Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
    margin-top: 0;
    padding: 0;
  }